import React, { useState, useEffect, useRef } from "react";
import { components } from "react-select";
import { Button } from "react-bootstrap";
import AddAlbumModal from "./AddAlbumModal";
import Pagination from "../../components/Pagination";
import pdfIcon from "../../assets/images/pdf-doc.svg";
import videoImg from "../../assets/images/video.png";
import { useQuery, useQueryClient, useMutation } from "react-query";
import * as querydata from "../../services/service";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from "react-image-crop";
import { cropImage } from "../dataService/DataService";
import "react-image-crop/dist/ReactCrop.css";
import setCanvasPreview from "./setCanvasPreview";
import { useContext } from "react";
import { GlobalContext } from "../../globalContext";
import  MediaFileButton  from "../../components/galleryCommonComponent/MediaFileButton";
export const offerSchema = Yup.object({
  // albumTitle: Yup.string().typeError("Album Title must be a string").required("Please enter Album Title"),
});


const { Option } = components;

const MultiValueLabel = (props) => (
  <Option {...props}>
    <input type="checkbox" checked={props.isSelected} onChange={() => null} />
    {props.label}
  </Option>
);

function AlbumView({
  albumId,
  websiteId,
  setAlbumId,
  setwebsiteId,
  setCropMediaModal1,
  setmainCroppedImage,
  setmainCroppedImageSorting,
  setmainCroppedFile,
  setting_value,
  setSingleFileProperties,
  setSingleCropImageProp,
  checkValForSingleImg,
  checkMultipleImageSection,
  mediaTypeCode,
  setUnitPlanPdf,
  setMediaType,
  checkValForBannerImage,
  setMainCroppedBannerImage,
  setmainCroppedBannerImageFile,
  setAllPropertiesImages,
  foredit = false
}) {
  const { bwebsiteId } = useContext(GlobalContext)
  const [base64, set64] = useState();
  const [loading, setLoading] = useState(false)
  const [coordinate, setCoordinate] = useState({
    scalex: 0,
    scaley: 0,
  });
  const [dimension, setDimension] = useState({
    height: 0,
    width: 0,
  });
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const [sortMediaTypeCode, setSelectedOptions] = useState([]);
  const cropMut = useMutation({
    mutationFn: cropImage,
    onSuccess: (data) => {
      let dataUrl = data?.data;
      let byteCharacters = atob(dataUrl);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const croppedBlob = new Blob([byteArray], { type: "image/png" });
      croppedBlob.urlname = croppedBlob.size;
      const imagename = URL.createObjectURL(croppedBlob);
      croppedBlob.imagename = imagename;

      if (checkValForSingleImg === "singleUnitPlanImage") {
        setSingleFileProperties(croppedBlob);
        setSingleCropImageProp(imagename);
      }
      setCroppedImage(URL.createObjectURL(croppedBlob));
      if (checkValForBannerImage === "BannerImage") {
        setMainCroppedBannerImage(imagename);
        setmainCroppedBannerImageFile(croppedBlob);
      }
      if (checkMultipleImageSection === "multipleImageSectionBtn") {
        setmainCroppedImage((prevImages) => [
          ...(prevImages || []),
          imagename,
        ]);
        setmainCroppedImageSorting((prevImages) => [
          ...(prevImages || []),
          croppedBlob.urlname,
        ]);

        setmainCroppedFile((prevData) => ({
          ...prevData,
          currentFileIds: [...(prevData.currentFileIds || []), croppedBlob],
        }));
        if (foredit)
          setAllPropertiesImages((prevImages) => [
            ...(prevImages || []),
            imagename,
          ]);

      }
      closeCropMediaModal();
      setCropMediaModal1(false);

    },
    onerror: (err) => {
      console.log("erorr : ", err);
    },
  });
  const cropMutation = cropMut;

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const navigatetoFolder = () => {
    setAlbumId("");
    setwebsiteId("");
  };
  const [albumSelectedId, setAlbumOptionId] = useState(albumId);
  const getAlbumFileData = useQuery(
    ["getAlbumFileData", +albumSelectedId, sortMediaTypeCode],
    () => querydata.getFileByAlbumId(albumSelectedId, sortMediaTypeCode)
  );

  const sortByData = useQuery(["sortByData"], () => querydata.getSortByData());

  let abIds = null;
  if (albumId===albumSelectedId) {
    abIds=albumId;
  }else{
    abIds=albumSelectedId;
  }

  // console.log("myCookieValue",myCookieValue)
  // for album drop down
  const getMediaAlbumFolData = useQuery(
    ["getMediaAlbumFolData", +websiteId],
    () => querydata.getMediaAlbumByWebId(websiteId)
  );

  const handleOnChangeAlbumSelectBox = (event) => {
    const selectedItemId = event.target.value;
    setAlbumOptionId(selectedItemId);
  };

  let filteredData =
    getAlbumFileData &&
    getAlbumFileData.data &&
    getAlbumFileData.data.data &&
    getAlbumFileData.data.data;
  const itemsPerPage = 10; // Number of items to display per page
  const totalPages = Math.ceil(
    getAlbumFileData?.data?.data.length / itemsPerPage
  );
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const [filter, setFilter] = useState("");

  let dataFileArray = Array.isArray(filteredData) ? filteredData : [];
  let startIndex = (currentPage - 1) * itemsPerPage;
  let endIndex = startIndex + itemsPerPage;
  let imgAndVdoData = dataFileArray.slice(startIndex, endIndex);
  const totalItems = dataFileArray.length;

  const handleInputChange = (event) => {
    setFilter(event.target.value);
    setCurrentPage(1);
  };

  filteredData =
    getAlbumFileData &&
    getAlbumFileData.data &&
    getAlbumFileData.data.data &&
    getAlbumFileData.data.data.filter(
      (file) =>
        file.media_title != null &&
        file.media_title.toLowerCase().includes(filter.toLowerCase())
    );
  dataFileArray = Array.isArray(filteredData) ? filteredData : [];
  startIndex = (currentPage - 1) * itemsPerPage;
  endIndex = startIndex + itemsPerPage;
  imgAndVdoData = dataFileArray.slice(startIndex, endIndex);

  //crop functionality
  const [crop, setCrop] = useState();
  const [image, setImage] = useState([]);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedFile, setCroppedFile] = useState(null);
  const first = setting_value.split("/")[0];
  const second = setting_value.split("/")[1];
  const [i, setI] = useState(0);
  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 90,
        },
        first / second,
        // setting_value,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  }

  const onCropComplete = (crop, filename) => {
    if (i >= 0) {
      const fetchData = async () => {
        try {
          const { scaleX, scaleY } = setCanvasPreview(
            imgRef.current, // HTMLImageElement
            previewCanvasRef.current, // HTMLCanvasElement
            convertToPixelCrop(
              crop,
              imgRef.current.width,
              imgRef.current.height
            )
          );
          let { x, y, width, height } = crop;

          x = x * scaleX;
          y = y * scaleY;

          setCoordinate((prevState) => ({
            ...prevState,
            scalex: x,
            scaley: y,
          }));

          setDimension((prev) => ({
            ...prev,
            height: (height) * scaleX,
            width: (width) * scaleY,
          }));
          set64(image);
          const dataUrl = previewCanvasRef.current.toDataURL();
          let newOptmise;
          let byteCharacters;

          newOptmise = dataUrl.split(",")[1];
          byteCharacters = atob(newOptmise);

          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const croppedBlob = new Blob([byteArray], { type: "image/png" });

          croppedBlob.urlname = croppedBlob.size;
          // Update your state or do something with the cropped image
          // setCroppedFile(croppedBlob);
        } catch (error) { }
      };
      fetchData();
    }
    setI(1);
  };

  const onCropComplete1 = (crop) => {
    cropMut.mutate({ base64, coordinate, dimension });

    // if (checkValForSingleImg === "singleUnitPlanImage") {
    //   setSingleFileProperties(croppedFile);
    //   setSingleCropImageProp(URL.createObjectURL(croppedFile));
    // }
    // setCroppedImage(URL.createObjectURL(croppedFile));
    // if (checkValForBannerImage === "BannerImage") {
    //   setMainCroppedBannerImage(URL.createObjectURL(croppedFile));
    //   setmainCroppedBannerImageFile(croppedFile);
    // }
    // if (checkMultipleImageSection === "multipleImageSectionBtn") {
    //   setmainCroppedImage((prevImages) => [
    //     ...(prevImages || []),
    //     URL.createObjectURL(croppedFile),
    //   ]);
    //   setmainCroppedImageSorting((prevImages) => [
    //     ...(prevImages || []),
    //     (croppedFile.urlname),
    //   ]);

    //   setmainCroppedFile((prevData) => ({
    //     ...prevData,
    //     currentFileIds: [...(prevData.currentFileIds || []), croppedFile],
    //   }));
    // }

    // closeCropMediaModal();
    // setCropMediaModal1(false);
  };

  const [deleteImgByFileId, setFileidForDeleteImg] = useState("");

  const [filterValue, setFilterValue] = useState("");
  const [show, setShow] = useState(false);
  const [selectedMediaFileId, setMediaFileId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isLoading, setIsLoading] = useState(false);
  const [shows, setShows] = useState(false);
  const [localimg, setLocalimg] = useState("");
  const closeImageEditmodal = () => setShows(false);

  // async function imageUrlToFile(imageUrl) {
  //   try {
  //     const response = await fetch(imageUrl);
  //     console.log("response",response)
  //     console.log("response.blob()",response.blob())
  //     const blob = await response.blob();
  //     console.log("blob",blob)
  //     const fileName = imageUrl.split("/").pop();
  //     console.log("fileName",fileName)
  //     const file = new File([blob], fileName, { type: blob.type });
  //     return file;
  //   } catch (error) {
  //     return null;
  //   }
  // }
  // async function main(unitPlanUrl, mtype) {
  //   try {
  //     let filetype = null;
  //     let fileNames = null;
  //     if (mtype === "VIDEO") {
  //       filetype = "video/mp4";
  //       fileNames = "video.mp4";
  //     }
  //     if (mtype === "PDF") {
  //       filetype = "application/pdf";
  //       fileNames = "file.pdf";
  //     }
  //     const pdfBlob = await imageUrlToFile(unitPlanUrl);
  //     let pdfFile = null;
  //     if (pdfBlob) {
  //       pdfFile = new File([pdfBlob], fileNames, {
  //         type: filetype,
  //       });
  //     } else {
  //     }
  //     return pdfFile;
  //   } catch (error) {
  //     return null;
  //   }
  // }
  //   async function imageUrlToFile(imageUrl, timeout = 500000) {
  //     const controller = new AbortController();
  //     const signal = controller.signal;

  //     // Set a timeout to abort the request
  //     const timeoutId = setTimeout(() => controller.abort(), timeout);

  //     try {
  //         const response = await fetch(imageUrl, { signal });
  //         console.log("response", response);

  //         const blob = await response.blob();
  //         console.log("blob", blob);

  //         clearTimeout(timeoutId);  // Clear the timeout if the fetch completes in time

  //         const fileName = imageUrl.split("/").pop();
  //         console.log("fileName", fileName);

  //         const file = new File([blob], fileName, { type: blob.type });
  //         return file;
  //     } catch (error) {
  //       console.log("error",error)
  //         if (error.name === 'AbortError') {
  //             console.error('Fetch aborted due to timeout');
  //         } else {
  //             console.error('Fetch error:', error);
  //         }
  //         return null;
  //     }
  // }

  // async function main(unitPlanUrl, mtype) {
  //     try {
  //         let filetype = null;
  //         let fileNames = null;
  //         if (mtype === "VIDEO") {
  //             filetype = "video/mp4";
  //             fileNames = "video.mp4";
  //         }
  //         if (mtype === "PDF") {
  //             filetype = "application/pdf";
  //             fileNames = "file.pdf";
  //         }

  //         const fileBlob = await imageUrlToFile(unitPlanUrl, 500000); // 10 seconds timeout
  //         let file = null;
  //         if (fileBlob) {
  //             file = new File([fileBlob], fileNames, {
  //                 type: filetype,
  //             });
  //         }

  //         return file;
  //     } catch (error) {
  //         console.error('Main function error:', error);
  //         return null;
  //     }
  // }
  // async function imageUrlToFile(imageUrl, timeout = 500000) {
  //   const controller = new AbortController();
  //   const signal = controller.signal;

  //   // Set a timeout to abort the request
  //   const timeoutId = setTimeout(() => controller.abort(), timeout);

  //   try {
  //     const response = await fetch(imageUrl, { signal });
  //     if (!response.ok) throw new Error('Network response was not ok.');

  //     const reader = response.body.getReader();
  //     const contentLength = +response.headers.get('Content-Length');
  //     let receivedLength = 0;
  //     const chunks = [];

  //     while (true) {
  //       const { done, value } = await reader.read();
  //       if (done) break;
  //       chunks.push(value);
  //       receivedLength += value.length;
  //     }

  //     clearTimeout(timeoutId);

  //     const blob = new Blob(chunks, { type: response.headers.get('Content-Type') });
  //     const fileName = imageUrl.split("/").pop();
  //     const file = new File([blob], fileName, { type: blob.type });
  //     return file;
  //   } catch (error) {

  //     if (error.name === 'AbortError') {
  //       console.error('Fetch aborted due to timeout');
  //     } else {
  //       console.error('Fetch error:', error);
  //     }
  //     return null;
  //   }
  // }

  // async function main(unitPlanUrl, mtype) {
  //   try {
  //     let filetype = null;
  //     let fileNames = null;
  //     if (mtype === "VIDEO") {
  //       filetype = "video/mp4";
  //       fileNames = "video.mp4";
  //     }
  //     if (mtype === "PDF") {
  //       filetype = "application/pdf";
  //       fileNames = "file.pdf";
  //     }

  //     const fileBlob = await imageUrlToFile(unitPlanUrl, 500000); // 10 seconds timeout
  //     let file = null;
  //     if (fileBlob) {
  //       file = new File([fileBlob], fileNames, {
  //         type: filetype,
  //       });
  //     }
  //     console.log("file",file)
  //     return file;
  //   } catch (error) {
  //     console.error('Main function error:', error);
  //     return null;
  //   }
  // }
  async function imageUrlToFile(imageUrl) {
    try {
      console.log("imageUrl", imageUrl)
      const response = await fetch(imageUrl);
      console.log("response", response)
      const blob = await response.blob();
      const fileName = imageUrl.split("/").pop();
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    } catch (error) {
      console.log("error", error)
      return null;
    }
  }
  async function main(unitPlanUrl, mtype) {
    try {
      let filetype = null;
      let fileNames = null;
      if (mtype === "VIDEO") {
        filetype = "video/mp4";
        fileNames = "video.mp4";
      }
      if (mtype === "PDF") {
        filetype = "application/pdf";
        fileNames = "file.pdf";
      }
      const pdfBlob = await imageUrlToFile(unitPlanUrl);
      let pdfFile = null;
      if (pdfBlob) {
        pdfFile = new File([pdfBlob], fileNames, {
          type: filetype,
        });
      } else {
      }
      console.log("pdfFile1111111111111111111111", pdfFile)
      return pdfFile;
    } catch (error) {
      return null;
    }
  }
  // const imgSearch =()=>
  const showImageEditModal = (mediaFileId, mediaType) => {
    setMediaFileId((prevvalues) => {
      return mediaFileId;
    });
    const fetchData = async () => {
      try {
        const result = await querydata.getImageDataByMediaFileId(mediaFileId);
        console.log("result", result)
        if (mediaType === "VIDEO") {
          setmainCroppedImage((prevImages) => [
            ...(prevImages || []),
            result?.data?.media_file_path,
          ]);
          setmainCroppedImageSorting((prevImages) => [
            ...(prevImages || []),
            +result?.data?.size,
          ]);
          // setmainCroppedImage();
          if (result?.data?.media_file_path) {
            // setLoading(true)
            // main(result?.data?.media_file_path, mediaType).then((File) => {
            // setLoading(false)
            // setCropMediaModal1(false);              
            // if (File) {
            setmainCroppedFile((prevData) => ({
              ...prevData,
              // currentFileIds: File,
              currentFileIds: [...(prevData.currentFileIds || []), result?.data?.media_file_path],
            }));
            // } else {
            //   console.log('Failed to create file');
            // }
            // });
          }
        }
        if (checkValForSingleImg === "singleUnitPlanImage") {
          console.log("mediaType", mediaType)
          if (mediaType === "PDF") {
            setUnitPlanPdf(result?.data?.media_file_path);
            setSingleCropImageProp(result?.data?.media_file_path);
            if (result?.data?.media_file_path) {
              main(result?.data?.media_file_path, mediaType).then((File) => {
                if (File) {
                  setSingleFileProperties(File);
                } else {
                  console.log('Failed to create file');

                }
              });
            }
            // setUnitPlanPdf(result);
            // setSingleFileProperties(result);
          }
        }

        setGetSinleImageData(result);
      } catch (error) {
        toast.dismiss();
        toast.error("Failed to fetch image");
      }
    };
    fetchData();
    if (mediaType === "PDF") {
      setMediaType(mediaType);
      setCropMediaModal(false);
      setCropMediaModal1(false);
    } else if (mediaType === "VIDEO") {
      setMediaType(mediaType);

      setCropMediaModal(false);
      setCropMediaModal1(false);
    } else {
      setShows(false);
      setCroppedImage("");
      showCropMediaModal();
    }
  };
  const [cropMediaModal, setCropMediaModal] = useState(false);
  const closeCropMediaModal = () => setCropMediaModal(false);
  const showCropMediaModal = () => {
    setCropMediaModal(true);
  };

  const handleSelectChange = (event) => {
    const val = event.target.value;
    const selectMediaTypeCode = { sortBy: val };
    setSelectedOptions(selectMediaTypeCode);
    const updatedFormData = new FormData();
    updatedFormData.append("sortBy", val);
    // Assuming formData is updated based on your dropdown change
    // Refetch the query with the new albumId and updated formData
    getAlbumFileData.refetch(["getAlbumFileData", +albumId, updatedFormData]);
  };

  const [getSingleImageData, setGetSinleImageData] = useState(null);

  useEffect(() => {
    setImage(
      getSingleImageData &&
      getSingleImageData.data &&
      getSingleImageData.data.media_file_path
    );
  }, [getSingleImageData]);

  useEffect(() => {
    if (selectedMediaFileId !== null) {
      const fetchData = async () => {
        try {
          const result = await querydata.getImageDataByMediaFileId(
            selectedMediaFileId
          );

          if (mediaTypeCode === "PDF") {
          }

          setGetSinleImageData(result);
        } catch (error) {
          toast.dismiss();
          toast.error("Failed to fetch image");
        }
      };

      fetchData();
    }
  }, [selectedMediaFileId]);

  const blobToDataUrl = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  let initialValues = {
    albumFile:
      (getSingleImageData &&
        getSingleImageData.data &&
        getSingleImageData.data &&
        getSingleImageData.data.media_file_path) ||
      "",
    imgName:
      (getSingleImageData &&
        getSingleImageData.data &&
        getSingleImageData.data &&
        getSingleImageData.data.media_title) ||
      "",
    media_caption:
      (getSingleImageData &&
        getSingleImageData.data &&
        getSingleImageData.data.media_caption) ||
      "",
    seoTags:
      (getSingleImageData &&
        getSingleImageData.data &&
        getSingleImageData.data.seo_tags) ||
      "",
  };

  useEffect(() => {
    setValues({ ...initialValues });
    setFieldValue({ ...initialValues });
  }, [getSingleImageData]);

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit: (values, action) => {
      const formData = new FormData();

      if (croppedFile !== null) {
        formData.append("albumFile", croppedFile);
      } else {
        formData.append("albumFile", values.albumFile);
      }
      formData.append("seoTags", values.seoTags);
      formData.append("media_title", values.imgName);
      formData.append("media_caption", values.media_caption);
      if (selectedMediaFileId > 0) {
        updateprojectMutation.mutate({ formData, fileId: selectedMediaFileId });
      } else {
        toast.dismiss();
        toast.error("Please contact to administrator");
      }
    },
  });
  const updateprojectMutation = useMutation(querydata.updateImageAndDetails, {
    onError: (error) => {
      toast.dismiss();
      toast.error(error.message);
    },
    onSuccess: (data) => {
      setIsLoading(false);
      toast.dismiss();
      toast.success(data.message);
      queryClient.invalidateQueries("getAlbumFileData");
      queryClient.invalidateQueries("getMediaAlbumFolData");
      closeImageEditmodal();
      closeCropMediaModal();
      closeDialogModal();
    },
    onSettled: () => {
      setIsLoading(false); // Set loading to false on success or error
    },
    onMutate: () => {
      setIsLoading(true); // Set loading to true before making the request
    },
  });

  const deleteSingleAlbumImg = (deleteImgByFileId) => {
    setFileidForDeleteImg(deleteImgByFileId);
    closeImageEditmodal();
  };

  useEffect(() => {
    if (deleteImgByFileId !== null && deleteImgByFileId !== "") {
      const fetchData = async () => {
        try {
          const response = await querydata.deleteAlbumImg(deleteImgByFileId);

          if (response) {
            toast.dismiss();
            toast.success(response.message);
            queryClient.invalidateQueries("getAlbumFileData");
            queryClient.invalidateQueries("getMediaAlbumFolData");
          }
        } catch (error) {
          toast.dismiss();
          toast.success(error.message);
        }
      };

      fetchData();
    }
  }, [deleteImgByFileId]);

  const [showDialogModal, setShowDialogModal] = useState(false);
  const handleShowDialogModal = () => {
    setShowDialogModal(true);
  };
  const closeDialogModal = () => setShowDialogModal(false);
  const imageSizeBytes =
    (getSingleImageData &&
      getSingleImageData.data &&
      getSingleImageData.data.size) ||
    0;

  const convertBytesToMB = (bytes) => {
    // Convert to Megabytes (MB)
    const mb = bytes / (1024 * 1024);
    return mb;
  };

  const imageSizeMB = convertBytesToMB(imageSizeBytes);
  const [sortOrder, setSortOrder] = useState("");
  const [sortedData, setSortedData] = useState(dataFileArray);

  const handleFilterChange = (event) => {
    const newSortOrder = event.target.value;
    setSortOrder(newSortOrder);
  };

  useEffect(() => {
    const sortData = (data, order) => {
      const sortedData = [...data];
      switch (order) {
        case "ASC":
          sortedData.sort(
            (a, b) => new Date(a.created_on) - new Date(b.created_on)
          );
          break;
        case "DESC":
          sortedData.sort(
            (a, b) => new Date(b.created_on) - new Date(a.created_on)
          );
          break;
        default:
          // If order is not ASC or DESC, show the data in its original order
          // You can modify this default behavior based on your requirements
          break;
      }
      return sortedData; // Do not slice data here
    };

    // Apply sorting when sortOrder changes
    const newSortedData = sortData(dataFileArray, sortOrder);
    setSortedData(newSortedData);
  }, [dataFileArray.length, sortOrder]);

 

  return (
    <>
      {loading ? (
        <div className="container-fluid dashboard-default">
          <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
            <div className="loader-container">
              <div className="spinner-border text-dark" role="status">
                <span className="visually-hidden">Loading...</span>

              </div>
            </div>
          </div>
        </div>

      ) : (

        <div className="">
          <div className="container-fluid dashboard-default">
            <div className="page-heading d-flex flex-wrap align-items-center justify-content-between">
              <nav className="w-100" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a
                      className="text-decoration-none"
                      onClick={navigatetoFolder}
                    >
                      Manage Albums
                    </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Gallery Assets
                  </li>
                </ol>
              </nav>
              <div className="left-header">
                <h4 className="fw-normal m-0">
                  Gallery <strong>Assets</strong>
                </h4>
              </div>
              <div className="header-action">
             <MediaFileButton webId={websiteId} buttonText={'ADD NEW FILE'} handleShow={handleShow}/>
               
              {/* <Button
                className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2"
                onClick={handleShow}
              >
                Add New File
              </Button> */}

                <AddAlbumModal
                  albumId={abIds}
                  show={show}
                  headerText={`Upload Media`}
                  handleClose={handleClose}
                />
              </div>
            </div>
            <div className="row mt-lg-5 mt-md-4 mt-3">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header bg-transparent border-bottom py-3 d-flex align-items-center justify-content-between">
                    <div className="left-header">
                      <div className="col-12 mb-0">
                        <label className="position-relative">
                          <input
                            type="text"
                            maxLength={20}
                            className="form-control rounded-1 py-2 shadow-none px-2 top-0"
                            onChange={handleInputChange}
                            name="imgSearch"
                            placeholder="search...."
                          />
                        </label>
                      </div>
                    </div>
                    <div className="right-action d-flex">
                      <div className="atmosphere-hr">
                        <label className="form-label form-control-sm mb-1 p-0">
                          Select Album
                        </label>

                        <select
                          className="form-control rounded-1 form-select"
                          onChange={handleOnChangeAlbumSelectBox}
                          defaultValue={albumId}
                        >
                          {getMediaAlbumFolData.data &&
                            getMediaAlbumFolData.data.data &&
                            getMediaAlbumFolData.data.data.map((option) => (
                              <option
                                key={option.media_album_id}
                                value={option.media_album_id}
                              >
                                {option.media_album}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="atmosphere-hr ms-2 me-2">
                        <label className="form-label form-control-sm mb-1 p-0">
                          Sort By
                        </label>
                        <div className="position-relative multiselect-box ">
                          <div className="ms-options-wrap">
                            <select
                              className="form-control rounded-1 form-select"
                              // onChange={handleSelectChange}
                              onChange={handleFilterChange}
                            >
                              <option value="">---Select---</option>
                              <option value="ASC">Date Ascending</option>
                              <option value="DESC">Date Descending</option>
                            </select>
                            {/* <select
                            className="form-control rounded-1 form-select"
                            onChange={handleSelectChange}
                          >
                            <option>Select </option>
                            {sortByData.data &&
                              sortByData.data.data &&
                              sortByData.data.data.map((mediaType) => (
                                <option
                                  key={mediaType.media_type_id}
                                  value={mediaType.media_type_code}
                                >
                                  {mediaType.media_type}
                                </option>
                              ))}
                          </select> */}
                          </div>
                        </div>
                      </div>
                      <div></div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="gallery-assets">
                      <ul className="list-unstyled m-0 p-0 d-flex flex-wrap align-items-center">
                        {sortedData &&
                          sortedData
                            .slice(startIndex, endIndex)
                            .map((imgData) => (
                              bwebsiteId === "AHR" && imgData?.media_type_code === "VIDEO" ? (
                                ""
                              ) : (

                                <li key={imgData.media_file_id}>
                                  <div className="album-view border rounded-3 position-relative overflow-hidden">
                                    <div className="album-head position-absolute px-3 py-3 start-0 top-0 w-100 d-flex align-items-center z-1 justify-content-between"></div>
                                    <div className="album-set-icon">
                                      <a
                                        onClick={() =>
                                          showImageEditModal(
                                            imgData.media_file_id,
                                            imgData.media_type_code
                                          )
                                        }
                                        className="position-relative text-decoration-none text-white d-flex px-2 py-3"
                                      >
                                        {imgData.media_type_code === "VIDEO" ? (
                                          <img
                                            src={videoImg}
                                            alt={imgData.media_title}
                                            title={imgData.media_title}
                                          />
                                        ) : imgData.media_type_code === "PDF" ? (
                                          <img
                                            src={pdfIcon}
                                            alt={imgData.media_title}
                                            title={imgData.media_title}
                                          />
                                        ) : (
                                          <img
                                            src={imgData.media_file_path}
                                            alt={imgData.media_title}
                                            title={imgData.media_title}
                                            style={
                                              imgData.media_file_path.endsWith(
                                                ".png"
                                              ) ||
                                                imgData.media_file_path.endsWith(
                                                  ".svg"
                                                )
                                                ? {
                                                  backgroundColor: "darkgrey",
                                                }
                                                : {
                                                  backgroundColor: "",
                                                }
                                            }
                                          />
                                        )}
                                        <div className="album-title position-absolute start-0 w-100 z-1 bottom-0 py-3 px-3 text-center">
                                          <h6 className="mb-0">
                                            {imgData.media_title}{" "}
                                          </h6>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              )
                            ))}
                      </ul>
                      <div className="atmosphere-pagination album-pagination mt-4 d-flex justify-content-end">
                        <nav aria-label="...">
                          <ul className="pagination m-0 d-flex justify-content-end">
                            <Pagination
                              currentPage={currentPage}
                              totalPages={totalPages}
                              onPageChange={handlePageChange}
                              visiblePages={itemsPerPage}
                              totalItems={totalItems}
                            />
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal size="xl" show={shows} onHide={closeImageEditmodal}>
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">{"Edit Media"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getSingleImageData && (
            <div className="modal-body px-lg-5 px-md-4 px-3 py-lg-5 py-md-4 py-3">
              <div className="row">
                <div className="col-md-6">
                  <div className="large-album-cover position-relative">
                    {!croppedImage && (
                      <img
                        className="w-100"
                        name="media_file_path"
                        src={getSingleImageData.data.media_file_path}
                        alt=""
                        title=""
                      />
                    )}
                    {croppedImage && (
                      <img
                        src={croppedImage}
                        alt="Cropped"
                        style={{ maxWidth: "100%" }}
                      />
                    )}{" "}
                    {/* Use src attribute */}
                    {getSingleImageData.data.media_type_code === "VIDEO" ? (
                      <>
                        <img className="w-100" src={videoImg} alt="video"></img>
                      </>
                    ) : (
                      <div className="edit-album-cover position-absolute end-0 top-0 m-3 z-3">
                        <span
                          className="btn btn-sm btn-secondary"
                          onClick={showCropMediaModal}
                        >
                          <i className="fa fa-pencil me-1"></i> Edit
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="uploaded-album-info">
                    <div className="top-album-info rounded-2 bg-light py-4 px-4">
                      <ul className="list-unstyled m-0 p-0">
                        <li>
                          <span className="ulabel opacity-50">Uploaded By</span>
                          <span className="uvalue fw-semibold">
                            {getSingleImageData.data.user_name}
                          </span>
                        </li>
                        <li>
                          <span className="ulabel opacity-50">Size</span>

                          <span className="uvalue fw-semibold">
                            {imageSizeMB.toFixed(2)}MB
                          </span>
                        </li>
                        <li>
                          <span className="ulabel opacity-50">Date</span>
                          <span className="uvalue fw-semibold">
                            {moment(getSingleImageData.data.created_on).format(
                              "MMMM D, YYYY HH:mm:ss"
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="ulabel opacity-50">Dimension</span>
                          <span className="uvalue fw-semibold">
                            {getSingleImageData.data.image_height}x
                            {getSingleImageData.data.image_width}
                          </span>
                        </li>
                        <li>
                          <span className="ulabel opacity-50">Extension</span>
                          <span className="uvalue fw-semibold">
                            {getSingleImageData.data.image_format}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <form>
                      <div className="mb-4">
                        {getSingleImageData.data.media_type_code === "VIDEO" ? (
                          <label className="form-label" htmlFor="Name">
                            Video Title
                          </label>
                        ) : (
                          <label className="form-label" htmlFor="Name">
                            Image Title
                          </label>
                        )}
                        <div className="mb-4">
                          <input
                            type="text"
                            name="imgName"
                            onChange={handleChange}
                            value={values.imgName}
                            className="form-control py-3 shadow-none px-4"
                            maxLength={100}
                          />
                        </div>
                      </div>

                      <div className="mb-4">
                        <label className="form-label" htmlFor="Name">
                          SEO Image Tags
                        </label>
                        <div className="mb-4">
                          <div className="position-relative multiselect-box">
                            <div className="mb-4">
                              <input
                                type="text"
                                onChange={handleChange}
                                name="seoTags"
                                value={values.seoTags}
                                className="form-control py-3 shadow-none px-4"
                                maxLength={200}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="mb-4">
                        <label className="form-label" htmlFor="Name">
                          Caption
                        </label>
                        <div className="mb-4">
                          <input
                            type="text"
                            onChange={handleChange}
                            name="media_caption"
                            value={values.media_caption}
                            className="form-control py-3 shadow-none px-4"
                            maxLength={255}
                          />
                        </div>
                      </div>

                      <div className="col-12 pt-2 text-end mb-2">
                        <input
                          type="reset"
                          value="Cancel"
                          onClick={closeImageEditmodal}
                          className="btn btn-primary btn-theme outline-btn text-uppercase px-3 py-2 me-3"
                        />

                        <Button
                          type="button"
                          onClick={() =>
                            deleteSingleAlbumImg(
                              getSingleImageData.data.media_file_id
                            )
                          }
                          className="btn bg-danger border-danger text-white btn-theme text-uppercase px-3 py-2 me-3"
                        >
                          <span>Delete</span>
                        </Button>
                        {!croppedImage && (
                          <input
                            onClick={handleSubmit}
                            type="submit"
                            value="Save"
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 "
                          />
                        )}
                        {croppedImage && (
                          <Button
                            onClick={handleShowDialogModal}
                            className="btn btn-primary btn-theme fw-semibold text-uppercase px-3 py-2 me-3"
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
        </Modal.Body>
      </Modal>

      {/* start  crop modal */}
      {/* <Modal
        show={cropMediaModal}
        onHide={closeCropMediaModal}
        className="imagecropmodel"
        backdrop="static"
      >
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">{"Crop Media"}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className=" overflow-x-auto overflow-y-auto"
          style={{ minHeight: "772px" }}
        >
        
          <div
            className="crop-now overflow-x-auto overflow-y-auto"
            style={{
              width:
                getSingleImageData &&
                getSingleImageData.data &&
                getSingleImageData.data.image_width,
            }}
          >
            {croppedImage && (
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={onCropComplete}
                aspect={first / second}
              >
                <img alt="" src={image} onLoad={onImageLoad} />

              </ReactCrop>
            )}

            {!croppedImage && (
              <ReactCrop
                crop={crop}
                onChange={(c) => setCrop(c)}
                onComplete={onCropComplete}
                aspect={first / second}
              >
                <img
                  className="before-crop-image"
                  alt=""
                  src={image}
                  onLoad={onImageLoad}
                />

              </ReactCrop>
            )}
          </div>

        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onCropComplete1}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal> */}
      <Modal
        size="xl"
        show={cropMediaModal}
        onHide={closeCropMediaModal}
        className="imagecropmodel"
        backdrop="static"
      >
        <Modal.Header className="modal-header px-5 py-4" closeButton>
          <Modal.Title className="modal-title fs-5">{"Crop Media"}</Modal.Title>
        </Modal.Header>
        <div
          className=" overflow-y-auto d-flex justify-content-center align-items-start 1"
          style={{ maxWidth: "800px", minHeight: "400px", width: "100%", margin: "0 auto" }}
        >
          <div
            className="crop-now text-center"
            style={{
              maxWidth: "800px",
            }}
          >
            <ReactCrop
              style={{ maxWidth: "100%" }}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={onCropComplete}
              aspect={first / second}
            >
              <img
                alt=""
                src={image}
                onLoad={onImageLoad}
                ref={imgRef}
                // crossOrigin="anonymous"
                style={{ maxWidth: "100%" }}
              />
            </ReactCrop>

            {/* dont delete this canvas as this canvas image ref is saved  */}
            <canvas
              ref={previewCanvasRef}
              className="mt-4"
              style={{
                display: "none",
                border: "1px solid black",
                objectFit: "contain",
                width: 150,
                // height: 150,
              }}
            />
          </div>
        </div>
        <Modal.Footer>
          <Button variant="primary" className="res text-uppercase" onClick={onCropComplete1} disabled={cropMutation.isLoading}>
            {cropMutation.isLoading ? (
              <div className="spinner-border" role="status"></div>
            ) : (
              "Save Changes"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End crop Modal */}

      {/* START MEDIA DIALOG MODAL */}

      <Modal show={showDialogModal} onHide={closeDialogModal}>
        <Modal.Header closeButton>
          <Modal.Title>Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Cropping Image Connot Be Revert...?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="text-uppercase" onClick={closeDialogModal}>
            Close
          </Button>
          <Button variant="primary" className="text-uppercase" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
      {/* END MEDIA DIALOG MODAL */}


    </>
  );
}

export default AlbumView;
